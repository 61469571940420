import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Paper,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    AiFillWarning,
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlinePlus,
} from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import DataNotFound from '../../components/DataNotFound';
import NewFormModal from '../../components/Modal/NewFormModal';
import ErrorMessage from '../../components/error/ErrorMessage';
import { ROLENAME, checkAllPermissionList } from '../../helpers/commonUses';
import GetRolePermission from '../../hooks/permission/GetRolePermission';
import GetUserPermission from '../../hooks/permission/GetUserPermission';
import { useAuth } from '../../hooks/useAuth';
import LoadingPage from '../LoadingPage/LoadingPage';
import AddApprovalType from './AddApprovalType';
import EditApprovalType from './EditApprovalType';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
    fromDate: Yup.string().required('Start is required'),
    toDate: Yup.string().required('End is required'),
});

//Menu Style
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const LoanApprovalMangeList = () => {
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [transferData, setTransferData] = useState([]);
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(10);
    const [error, setError] = useState({
        isError: false,
        errorMessage: null,
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorEditMessage, setErrorEditMessage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const { storePermissionPath } = GetUserPermission();
    const { storeRolePermissionPath } = GetRolePermission();

    //Router Path
    const routerPermissionPath = [
        'api/loan-approval-setting/create',
        'api/loan-approval-setting/update/{id}',
        'api/loan-approval-setting/delete/{id}',
    ];

    const navigate = useNavigate();

    const menuOpen = Boolean(anchorEl);

    //Open Modal
    const handleOpen = () => {
        setOpen(true);
        handleMenuClose();
    };
    const handleModalClose = () => {
        setOpen(false);
    };

    //Edit Modal
    const handleEditModalClose = () => {
        setOpenEdit(false);
    };
    const handleCloseEditModal = () => {
        setOpenEdit(true);
        handleMenuClose();
    };

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setCurrentIndex(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
        handleMenuClose();
        handleModalClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    //Pagination
    const changePagination = (e, value) => {
        setPage(value);
    };

    //react-hook-form
    const { reset } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const { role, token } = useAuth();

    const deleteWeekend = async () => {
        setLoading(true);
        try {
            const res = await Axios.delete(`/loan-approval-setting/delete/${currentIndex}`, {
                headers: {
                    Authorization: token
                        ? 'Bearer ' + token
                        : 'Bearer ' + localStorage.getItem('token'),
                },
            });
            if (res.data.status === 'success') {
                toast.success('Load Approval Deleted');
                //for last data delete to fix bug
                if (transferData?.length === 1 && page !== 1) {
                    setPage(1);
                }
                setShouldFetchData(!shouldFetchData);
                handleDialogClose();
                setLoading(false);
            } else {
                toast.warn('Something went wrong');
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            toast.warn('Something went wrong');
            setLoading(false);
        }
    };

    //Add Transfer From Submit
    const handleAddSubmit = async (data) => {
        try {
            const res = await Axios.post(
                '/loan-approval-setting/create',
                {
                    employee_id: parseInt(data?.employee),
                    rank: parseInt(data?.rank),
                },
                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res?.data?.status === 'success') {
                toast.success('Loan Approval Added');
                setOpen(false);
                setShouldFetchData((prev) => !prev);
                reset();
                setErrorMessage(null);
            } else if (res.data.success === '200') {
                toast.info("You don't have loan approval create permissions, contact admin");
            } else if (res?.data?.status === 'error') {
                toast.warn(res?.data?.error[0]);
                setErrorMessage(null);
            }
        } catch (error) {
            console.log(error);
            toast.warn('Something went wrong');
            setErrorMessage(error?.response?.data?.errors);
        }
    };

    //Edit Transfer From Submit
    const handleEditSubmit = async (data) => {
        try {
            const res = await Axios.post(
                `/loan-approval-setting/update/${currentIndex}`,
                {
                    employee_id: parseInt(data?.employee),
                    rank: parseInt(data?.rank),
                },
                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res?.data?.status === 'success') {
                toast.success('Loan Approval Updated');
                setOpenEdit(false);
                setShouldFetchData((prev) => !prev);
                setErrorEditMessage(null);
            } else if (res.data.success === '200') {
                toast.info("You don't have loan approval update permissions, contact admin");
            } else if (res?.data?.status === 'error') {
                toast.warn(res?.data?.error[0]);
                setErrorEditMessage(null);
            }
        } catch (error) {
            console.log(error);
            toast.warn('Something went wrong');
            setErrorEditMessage(error?.response?.data?.errors);
        }
    };

    //Get Manage List Data
    const getManageList = async () => {
        try {
            setLoading(true);
            const res = await Axios.get(
                '/loan-approval-setting',

                {
                    params: {
                        page: page,
                    },
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            console.log('getAnnouncementList - res:', res);
            if (res.data.status === 'success') {
                setTransferData(res?.data?.response_data?.data);
                setCountPage(res?.data?.response_data?.last_page);
                setError({
                    isError: false,
                    errorMessage: null,
                });
            } else if (res.data.success === '200') {
                toast.info("You don't have loan type  permissions, contact admin");
                setTransferData([]);
                navigate('/unauthorized', { replace: true });
            } else {
                toast.warn('Something went wrong');
                setTransferData([]);
            }
            setLoading(false);
        } catch (error) {
            console.log('branch list', error);
            setLoading(false);
            setTransferData([]);
            if (error?.response?.status !== 422) {
                setError({
                    isError: true,
                    errorMessage: error?.response?.data?.message,
                });
            }

            if (error?.response?.status === 422) {
                toast.warn('Data not found');
            } else {
                toast.warn('Something went wrong');
            }
        }
    };

    useEffect(() => {
        getManageList();
    }, [page, shouldFetchData]);

    if (loading) {
        return <LoadingPage />;
    }
    if (error?.isError) {
        return <ErrorMessage message={error?.errorMessage} />;
    }
    return (
        <Paper elevation={2} sx={{ py: 2, px: { xs: 2, md: 3, xl: 5 } }}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexWrap="wrap"
                gap={'10px'}
            >
                <Typography variant="h5">Loan Approval List</Typography>
                {role === ROLENAME ? (
                    <Button
                        onClick={handleOpen}
                        variant={'contained'}
                        startIcon={<AiOutlinePlus />}
                    >
                        Add Loan Rank
                    </Button>
                ) : (
                    (storePermissionPath.includes(routerPermissionPath[0]) ||
                        storeRolePermissionPath.includes(routerPermissionPath[0])) && (
                        <Button
                            onClick={handleOpen}
                            variant={'contained'}
                            startIcon={<AiOutlinePlus />}
                        >
                            Add Loan Rank
                        </Button>
                    )
                )}
            </Stack>
            <Divider sx={{ mt: 2 }} />

            <TableContainer sx={{ mt: 5, pb: 2 }}>
                <Divider />

                <Table sx={{ minWidth: 650 }} aria-label="simple table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} width={100}>
                                #SI
                            </TableCell>
                            <TableCell align={'left'}>Employee</TableCell>
                            <TableCell align={'left'}>Rank</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transferData?.length <= 0 ? (
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={4}>
                                    <DataNotFound />
                                </TableCell>
                            </TableRow>
                        ) : (
                            transferData.map((item, index) => {
                                return (
                                    <TableRow key={item?.id}>
                                        <TableCell align="left">
                                            {page !== 1 && index + 1 === 10
                                                ? page
                                                : page !== 1 && page - 1}
                                            {index + 1 === 10 && page !== 1 ? 0 : index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item?.employee_name || 'N/A'}
                                        </TableCell>
                                        <TableCell align="left">{item?.rank || 'N/A'}</TableCell>

                                        {/* </TableCell> */}
                                        <TableCell align="right">
                                            {role === ROLENAME ? (
                                                <IconButton
                                                    EditTransfer
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(e) =>
                                                        handleClick(e, item?.id, item?.status)
                                                    }
                                                >
                                                    <BsThreeDotsVertical size={20} />
                                                </IconButton>
                                            ) : checkAllPermissionList(
                                                  routerPermissionPath.slice(1),
                                                  storePermissionPath?.concat(
                                                      storeRolePermissionPath
                                                  )
                                              ) ? (
                                                <>
                                                    {' '}
                                                    <IconButton
                                                        EditTransfer
                                                        id="basic-button"
                                                        aria-controls={
                                                            open ? 'basic-menu' : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, item?.id)}
                                                    >
                                                        <BsThreeDotsVertical size={20} />
                                                    </IconButton>{' '}
                                                </>
                                            ) : (
                                                'N/A'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
                {page === 1 && transferData?.length < 10 ? null : (
                    <Stack spacing={2} alignItems="end" mt={3}>
                        <Pagination
                            count={countPage}
                            page={page}
                            onChange={changePagination}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: AiOutlineArrowLeft,
                                        next: AiOutlineArrowRight,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                )}
            </TableContainer>

            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {role === ROLENAME ? (
                    <MenuItem onClick={handleCloseEditModal}>
                        <BiEditAlt style={{ marginRight: '5px' }} /> Edit
                    </MenuItem>
                ) : (
                    (storePermissionPath.includes(routerPermissionPath[1]) ||
                        storeRolePermissionPath.includes(routerPermissionPath[1])) && (
                        <MenuItem onClick={handleCloseEditModal}>
                            <BiEditAlt style={{ marginRight: '5px' }} /> Edit
                        </MenuItem>
                    )
                )}

                {role === ROLENAME ? (
                    <MenuItem onClick={handleDialogOpen}>
                        <RiDeleteBin2Line style={{ marginRight: '5px' }} /> Delete
                    </MenuItem>
                ) : (
                    (storePermissionPath.includes(routerPermissionPath[2]) ||
                        storeRolePermissionPath.includes(routerPermissionPath[2])) && (
                        <MenuItem onClick={handleDialogOpen}>
                            <RiDeleteBin2Line style={{ marginRight: '5px' }} /> Delete
                        </MenuItem>
                    )
                )}
            </StyledMenu>

            {/* Add Modal  */}
            <NewFormModal
                openModal={open}
                handleModalClose={handleModalClose}
                modalTitle="Add new Rank"
                modalWidth="sm"
            >
                <AddApprovalType handleAddSubmit={handleAddSubmit} handleError={errorMessage} />
            </NewFormModal>

            {/* Edit Modal  */}
            <NewFormModal
                openModal={openEdit}
                handleModalClose={handleEditModalClose}
                modalTitle="Edit Rank"
                modalWidth="sm"
            >
                <EditApprovalType
                    handleAddSubmit={handleEditSubmit}
                    handleError={errorEditMessage}
                    id={currentIndex}
                />
            </NewFormModal>

            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Stack alignItems={'center'} justifyContent={'center'} direction={'row'}>
                        <AiFillWarning size={24} color={'#eed202'} />
                        <Typography sx={{ ml: 1 }} variant={'h5'}>
                            Confirm Delete!
                        </Typography>
                    </Stack>
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this rank? Once deleted, you will not get
                        this rank data back.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'error'} onClick={handleDialogClose}>
                        Disagree
                    </Button>
                    <Button variant={'contained'} color={'success'} onClick={deleteWeekend}>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default LoanApprovalMangeList;
