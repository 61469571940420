import moment from 'moment';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
// Number-to-word conversion
import { ToWords } from 'to-words';
import { sassTrue } from 'sass';
import Axios from '../api/Axios';

export const API_BASE_URL =
    window.location.host === 'hrm.frontdeskbd.com'
        ? 'https://hrm.goldeninfotech.net'
        : 'https://hrm.goldeninfotech.net';

//Variable
const currencyText = '/-';
export const bannerVariableName = ['tax_certificate_banner'];
export const taxSettingsVariableName = ['max_tax_fare_income', 'min_tax_payable'];

//Convert to words
export const toWordsFormat = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        currencyOptions: {
            name: 'Taka',
            singular: 'Taka',
            plural: 'Taka',
            symbol: 'Taka',
            fractionalUnit: {
                name: 'Paisa',
                singular: 'Paisa',
                plural: 'Paisa',
                symbol: 'Paisa',
            },
        },
    },
});

export const monthsInWords = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getCurrentMonthInWords = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    return monthsInWords[currentMonthIndex];
};

export const getMonthName = (monthNumber) => {
    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthsInWords[monthNumber - 1];
    } else {
        return 'Invalid';
    }
};

export const PaymentMethodOptions = [
    {
        label: 'Mobile Banking (Bkash)',
        value: 'Bkash',
    },
    {
        label: 'Mobile Banking (Nagad)',
        value: 'Nagad',
    },
    {
        label: 'Mobile Banking (Rocket)',
        value: 'Rocket',
    },
    {
        label: 'Bank Account',
        value: 'Bank',
    },
    {
        label: 'Agent Banking',
        value: 'Agent',
    },
    {
        label: 'BEFTN',
        value: 'BEFTN',
    },
    {
        label: 'Cash',
        value: 'Cash',
    },
];

//Rank options
export const rankDataOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
];

//Loan  type optoins
export const loanTypeOptons = [
    { id: 1, label: 'Running', value: 'running' },
    { id: 2, label: 'Pause', value: 'pause' },
    { id: 3, label: 'Close', value: 'closed' },
];

//Loan Main type optoins
export const loanMainTypeOptons = [
    { id: 1, label: 'Approve', value: 'approved' },
    { id: 2, label: 'Cancel', value: 'canceled' },
];

//Pf settings data
export const pfSettingsData = [
    { id: 1, label: 'Yearly', value: 'yearly' },
    { id: 2, label: 'Half Yearly', value: 'half_yearly' },
];

//Loan status
export const loanStatus = ['opening', 'running', 'pause', 'closed'];
export const loanMainStatus = ['pending', 'partially_approved', 'approved', 'canceled'];

export const renderFile = (filePath) => {
    return `${process.env.REACT_APP_BASE_URL}/${filePath}`;
};

//Check loan  status
export const checkLoanStatus = (status) => {
    if (status === loanStatus[3]) {
        return true;
    }
    return false;
};

//Check loan main status
export const checkLoanMainStatus = (status, getLoanStatus) => {
    if (
        status === loanMainStatus[2] ||
        status === loanMainStatus[3] ||
        getLoanStatus === loanStatus[3]
    ) {
        return true;
    }
    return false;
};

//Format date
export const dateFormat = ({ date, dateFormat = 'YYYY-MM-DD', format = 'DD MMM YYYY' }) => {
    if (date) {
        return moment(date, dateFormat).format(format);
    }
    return null;
};

//Show amount text
export const displayAmountText = (amount, isShowCurrency = true) => {
    if (amount === 0 || amount === null || amount === undefined) {
        return isShowCurrency ? `${0}${currencyText}` : '0';
    } else if (amount) {
        const formatAmount = new Intl.NumberFormat('en-IN').format(amount);
        return isShowCurrency ? `${formatAmount}${currencyText}` : formatAmount;
    }
    return isShowCurrency ? `${0}${currencyText}` : '0';
};

//Format amount
export const formatAmount = (amount) => {
    if (amount) {
        return new Intl.NumberFormat('en-IN').format(amount);
    }
    return 0;
};

//Calculate grand total
export const calculateGrandTotal = ({
    data = [],
    objectName,
    isShowCurrency = true,
    isFormatted = true,
}) => {
    if (data?.length > 0) {
        const removeUndefinedArray = data?.filter(function (element) {
            return element !== undefined;
        });
        const totalSum = removeUndefinedArray.reduce((accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject[objectName]);
        }, 0);

        return isFormatted
            ? displayAmountText(parseFloat(totalSum).toFixed(2), isShowCurrency)
            : totalSum;
    } else {
        return displayAmountText(0);
    }
};

//Calculate grand total
export const summationGrandTotal = ({
    data = [],
    objectName,
    isShowCurrency = true,
    isFormatted = true,
}) => {
    if (data?.length > 0) {
        const removeUndefinedArray = data?.filter(function (element) {
            if (element[objectName]) {
                return element;
            }
        });
        const totalSum = removeUndefinedArray.reduce((accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject[objectName]);
        }, 0);

        return isFormatted
            ? displayAmountText(parseFloat(totalSum).toFixed(2), isShowCurrency)
            : totalSum;
    } else {
        return displayAmountText(0);
    }
};

//Float amount format
export const floatAmountFormat = (amount, digit = 2) => {
    if (isNaN(amount) || amount === 0) {
        return 0;
    } else if (amount) {
        return parseFloat(parseFloat(amount).toFixed(digit));
    }
};

//Display craete updated date time
export const createUpdatedDateTime = ({
    createDate,
    updateDate,
    dateFormat = 'YYYY-MM-DD HH:mm:ss',
    format = 'DD MMM YYYY , hh:mm a',
}) => {
    if (createDate || updateDate) {
        return moment(updateDate ? updateDate : createDate, dateFormat).format(format);
    }
    return 'N/A';
};

//Fiscal year  excel
export const exportToExcelForFiscal = async (fileName, title, card1, card2, mainTable) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 1. Add Title
    worksheet.mergeCells('A1:E1'); // Merge cells for the title
    const titleCell = worksheet.getCell('A1');
    titleCell.value = title;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // 2. Add Card 1
    worksheet.addRow([]);
    const card1TitleCell = worksheet.getCell(`A3`);
    card1TitleCell.value = card1.title;
    card1TitleCell.font = { bold: true, size: 14 };

    card1.items.forEach((item, index) => {
        worksheet.addRow([`• ${item}`]);
        worksheet.getCell(`A${4 + index}`).alignment = { horizontal: 'left' };
    });

    // 3. Add Card 2
    worksheet.addRow([]);
    const card2TitleCell = worksheet.getCell(`A${worksheet.lastRow.number + 1}`);
    card2TitleCell.value = card2.title;
    card2TitleCell.font = { bold: true, size: 14 };

    card2.items.forEach((item, index) => {
        worksheet.addRow([`• ${item}`]);
        worksheet.getCell(`A${worksheet.lastRow.number}`).alignment = { horizontal: 'left' };
    });

    // 4. Add Text
    worksheet.addRow([]);
    const textRow = worksheet.getCell(`A${worksheet.lastRow.number + 1}`);
    textRow.value = 'The particulars of payment of deducted tax to the credit of the Government:';
    textRow.font = { italic: true, size: 12 };

    // 5. Add Main Table
    worksheet.addRow([]);
    const tableStartRow = worksheet.lastRow.number + 1;

    // Add table headers
    worksheet.addRow(mainTable.headers);
    const headerRow = worksheet.lastRow;
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
    });

    // Add table data
    mainTable.data.forEach((row) => {
        const dataRow = worksheet.addRow(row);
        dataRow.eachCell((cell) => {});
    });

    // Add Grand Total Row
    const totalRow = worksheet.addRow([
        'Total Amount',
        '',
        '',
        '',
        mainTable?.totalAmount[0],
        mainTable?.totalAmount[1],
    ]);
    worksheet.mergeCells(`A${totalRow.number}:D${totalRow.number}`); // Merge first 4 columns for total label
    totalRow.font = { bold: true };
    totalRow.alignment = { horizontal: 'left' };

    totalRow.eachCell((cell) => {});

    // Add Total Amount in Words
    worksheet.addRow([]);
    const totalWordsRow = worksheet.addRow([
        `Total Amount in Words: ${toWordsFormat.convert(parseFloat(mainTable?.amountInWord) || 0)}`,
        '',
        '',
        '',
        '',
        '',
    ]);
    worksheet.mergeCells(`A${totalWordsRow.number}:F${totalWordsRow.number}`); // Merge first 6 columns for total label
    totalWordsRow.font = { italic: true, bold: true };
    totalWordsRow.alignment = { horizontal: 'left' };

    totalWordsRow.eachCell((cell) => {});

    // Adjust column width
    worksheet.columns.forEach((col) => {
        col.width = 20;
    });

    // Generate Excel and save
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

//Tax certificate   excel
export const exportToExcelForTaxCertificate = async (fileName, title, card1, mainTable) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 1. Add Title
    worksheet.mergeCells('A1:E1'); // Merge cells for the title
    const titleCell = worksheet.getCell('A1');
    titleCell.value = title;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // 2. Add Card 1
    worksheet.addRow([]);
    const card1TitleCell = worksheet.getCell(`A3`);
    card1TitleCell.value = card1.title;
    card1TitleCell.font = { bold: true, size: 14 };

    card1.items.forEach((item, index) => {
        worksheet.addRow([`• ${item}`]);
        worksheet.getCell(`A${4 + index}`).alignment = { horizontal: 'left' };
    });

    // 3. Add Main Table
    worksheet.addRow([]);
    const tableStartRow = worksheet.lastRow.number + 1;

    // Add table headers
    worksheet.addRow(mainTable.headers);
    const headerRow = worksheet.lastRow;
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
    });

    // Add table data
    mainTable.data.forEach((row) => {
        const dataRow = worksheet.addRow(row);
        dataRow.eachCell((cell) => {});
    });

    // Add Grand Total Row
    const totalRow = worksheet.addRow(['Total Amount', mainTable?.totalAmount[0]]);
    totalRow.font = { bold: true };
    totalRow.alignment = { horizontal: 'left' };

    totalRow.eachCell((cell) => {});

    // Add Total Amount in Words
    worksheet.addRow([]);
    const totalWordsRow = worksheet.addRow([
        `Total Amount in Words: ${toWordsFormat.convert(parseFloat(mainTable?.amountInWord) || 0)}`,
        '',
    ]);
    worksheet.mergeCells(`A${totalWordsRow.number}:B${totalWordsRow.number}`);
    totalWordsRow.font = { italic: true, bold: true };
    totalWordsRow.alignment = { horizontal: 'left' };

    totalWordsRow.eachCell((cell) => {});

    // Adjust column width
    worksheet.columns.forEach((col) => {
        col.width = 20;
    });

    // Generate Excel and save
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

//Loan transcation  excel
export const exportToExcelForLoanTranscation = async (fileName, title, card1, mainTable) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 1. Add Title
    worksheet.mergeCells('A1:E1'); // Merge cells for the title
    const titleCell = worksheet.getCell('A1');
    titleCell.value = title;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // 2. Add Card 1
    worksheet.addRow([]);
    const card1TitleCell = worksheet.getCell(`A3`);
    card1TitleCell.value = card1.title;
    card1TitleCell.font = { bold: true, size: 14 };

    card1.items.forEach((item, index) => {
        worksheet.addRow([`• ${item}`]);
        worksheet.getCell(`A${4 + index}`).alignment = { horizontal: 'left' };
    });

    // 3. Add Main Table
    worksheet.addRow([]);
    const tableStartRow = worksheet.lastRow.number + 1;

    // Add table headers
    worksheet.addRow(mainTable.headers);
    const headerRow = worksheet.lastRow;
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
    });

    // Add table data
    mainTable.data.forEach((row) => {
        const dataRow = worksheet.addRow(row);
        dataRow.eachCell((cell) => {});
    });

    // Adjust column width
    worksheet.columns.forEach((col) => {
        col.width = 20;
    });

    // Generate Excel and save
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

//Convert image to base64
export const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

//Convert local iamge to base64
export const convertPublicImageToBase64 = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
    });
};

// Function to convert an image URL to base64
export const convertImageUrlToBase64 = async (imageUrl) => {
    try {
        // Fetch the image as an array buffer
        const response = await Axios.get(imageUrl, { responseType: 'arraybuffer' });

        // Convert the array buffer to a base64 string
        const binary = new Uint8Array(response.data);
        let base64String = '';
        for (let i = 0; i < binary.byteLength; i++) {
            base64String += String.fromCharCode(binary[i]);
        }

        // Encode the string to base64
        const base64 = btoa(base64String);

        // Determine the image type (default to 'png' if unknown)
        const contentType = response.headers['content-type'] || 'image/png';

        return `data:${contentType};base64,${base64}`;
    } catch (error) {
        console.error('Error converting image to base64:', error);
        return null;
    }
};

//Tax calculation
export function calculateTax(data, grossSalary, totalInvestment) {
    // Sample tax setting slabs (as received from the API)
    const taxSettingSlabs = data?.tax_setting_slabs || [];

    // Constants
    const MAX_TAX_FARE_INCOME = data?.max_tax_fare_income; // max_tax_fare_income
    const MIN_TAX_PAYABLE = data?.min_tax_payable; // min_tax_payable

    // Investment rebate settings
    const investmentRebate = data?.investmentRebate;

    // Calculate yearly gross salary
    const totalYearlyGrossSalary = grossSalary * 12;

    // Calculate one-third of yearly gross salary
    const oneThirdOfYearlyGrossSalary = totalYearlyGrossSalary / 3;

    // Determine taxable income
    let totalTaxableIncome;
    if (oneThirdOfYearlyGrossSalary > MAX_TAX_FARE_INCOME) {
        totalTaxableIncome = totalYearlyGrossSalary - MAX_TAX_FARE_INCOME;
    } else {
        totalTaxableIncome = totalYearlyGrossSalary - oneThirdOfYearlyGrossSalary;
    }

    // Calculate total tax based on slabs
    let remainingIncome = totalTaxableIncome;
    let totalTax = 0;

    for (const slab of taxSettingSlabs) {
        let slabAmount = slab?.amount;
        const slabPercentage = slab?.tax_percentage;

        let taxableAmount;
        if (slabAmount === 'rest of all') {
            taxableAmount = remainingIncome;
        } else {
            slabAmount = parseFloat(slabAmount);

            taxableAmount = Math.min(remainingIncome, slabAmount);
        }

        totalTax += (taxableAmount * slabPercentage) / 100;
        remainingIncome -= taxableAmount;

        if (remainingIncome <= 0) break;
    }

    // Calculate investment rebate
    const rebateOfTaxableIncome = totalTaxableIncome * (investmentRebate?.taxable_income / 100);
    const rebateOfTotalInvestment = totalInvestment * (investmentRebate?.total_investment / 100);
    const totalRebate = Math.min(
        rebateOfTaxableIncome,
        rebateOfTotalInvestment,
        investmentRebate?.max_value
    );

    // Calculate net tax
    let netTax = totalTax - totalRebate;
    if (netTax < MIN_TAX_PAYABLE) {
        netTax = MIN_TAX_PAYABLE;
    }

    // Monthly net tax
    const monthlyNetTax = netTax ? Math.ceil(netTax / 12) : 0;

    return {
        totalTax: floatAmountFormat(totalTax),
        totalRebate: floatAmountFormat(totalRebate),
        netTax: floatAmountFormat(netTax),
        monthlyNetTax,
    };
}

//Replace underscore line
export const replaceUnderScoreLine = (text = '') => {
    if (text) {
        return text
            .replace(/[^a-zA-Z0-9 ]/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
    }
    return 'N/A';
};
export const removeLSItemsWithPrefix = (prefix) => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(prefix)) {
            localStorage.removeItem(key);
        }
    });
};
