import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import FileUploadReport from '../../components/attachment/FileUploadReport';
import ApiErrorMessage from '../../components/error/ApiErrorMessage';
import { selectOptionsEmployee, selectOptionsLoanType } from '../../helpers/selectOptions';
import { useAuth } from '../../hooks/useAuth';
import GetUserPermission from '../../hooks/permission/GetUserPermission';
import GetRolePermission from '../../hooks/permission/GetRolePermission';
import { ROLENAME } from '../../helpers/commonUses';

const NewLoanRequestPage = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [employeeOPtion, setEmployeeOPtion] = useState([]);
    const [loanTypeOption, setLoanTypeOption] = useState([]);
    const [files, setFiles] = useState([]);
    const [pfInvestmentSetting, setPfInvestmentSetting] = useState({});
    const { storePermissionPath } = GetUserPermission();
    const { storeRolePermissionPath } = GetRolePermission();

    //Router Path
    const routerPermissionPath = ['api/loan-request/create'];

    const validationSchema = Yup.object().shape({
        client: Yup.string().required('Employee is required'),
        loanType: Yup.string().required('Loan type is required'),
        amount: Yup.string().required('Amount is required'),
        installment: Yup.string().required('Num of installment is required'),
        perInstallment: Yup.string().required('Amount per installment is required'),
        interestAmount: Yup.string(),
        reason: Yup.string(),
    });

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: { perInstallment: 0, interestAmount: '0' },
    });

    //Get value
    const getAmount = watch('amount') || 0;
    const getInstallment = watch('installment') || 0;
    const getLoanTypeValue = watch('loanType') || 0;

    const navigate = useNavigate();
    const { role, token } = useAuth();

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('employee_id', parseInt(data?.client));
            formData.append('loan_type_id', parseInt(data?.loanType));
            formData.append('loan_amount', parseFloat(data?.amount));
            formData.append('num_of_installment', parseFloat(data?.installment));
            formData.append('amount_per_installment', parseFloat(data?.perInstallment));
            formData.append('loan_details', data?.reason);
            if (files?.length > 0) {
                formData.append('attachment', files[0]);
            }

            const res = await Axios.post(`/loan-request/create`, formData, {
                headers: {
                    Authorization: token
                        ? 'Bearer ' + token
                        : 'Bearer ' + localStorage.getItem('token'),
                },
            });
            if (res?.data?.status === 'success') {
                toast.success('Loan Request Created');
                navigate('/loan-manage/list');
                setErrorMessage(null);
                reset();
            }
        } catch (error) {
            console.log(error);
            setErrorMessage(error?.response?.data?.errors);
            if (error?.response?.data?.status === 'error') {
                if (error?.response?.data?.errors?.length > 0) {
                    toast.error(error?.response?.data?.errors[0]);
                } else {
                    toast.error(error?.response?.data?.message);
                }
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    //Get Employee Data
    const getEmployee = async (signal) => {
        try {
            const res = await Axios.post(
                `/employee/all`,
                {},

                {
                    signal: signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                const getOptions = selectOptionsEmployee(res.data?.response_data);
                setEmployeeOPtion(getOptions);
            } else if (res.data.success === '200') {
                toast.info("You don't have all employee permissions, contact admin");
            } else {
                toast.warn('Something went wrong');
            }
        } catch (error) {
            console.log('error', error);
            toast.warn('Something went wrong');
        }
    };

    //Get Loan Type Data
    const getLoanType = async (signal) => {
        try {
            const res = await Axios.get(
                `/type-of-loan/all`,

                {
                    signal: signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                const getOptions = selectOptionsLoanType(res.data?.response_data);
                setLoanTypeOption(getOptions);
            }
        } catch (error) {
            console.log('error', error);
            toast.warn('Something went wrong about loan type');
        }
    };

    //Get PF Settings Data
    const getPfInvestmentSetting = async () => {
        try {
            const res = await Axios.get(
                '/pf-investment-interest',

                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            if (res.data.status === 'success') {
                setPfInvestmentSetting(res?.data?.response_data);
            } else {
                toast.warn('Something went wrong');
                setPfInvestmentSetting({});
            }
        } catch (error) {
            setPfInvestmentSetting({});
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getEmployee(signal);
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getLoanType(signal);
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        getPfInvestmentSetting();
    }, []);

    //Calculate per installment
    useEffect(() => {
        if (getAmount > 0 && getInstallment > 0 && getLoanTypeValue) {
            // Fetch the selected loan type's interest rate
            const loanType = loanTypeOption.find((item) => item.value === getLoanTypeValue);
            if (!loanType) {
                toast.error('Please select a valid loan type.');
                setValue('perInstallment', 0);
                return;
            }

            const totalInterestRate = loanType?.interestRate + pfInvestmentSetting?.interest;

            // Calculate total amount with interest
            const totalAmountWithInterest =
                parseFloat(getAmount) + parseFloat(getAmount * totalInterestRate) / 100;

            //Set interest amount value
            setValue('interestAmount', totalAmountWithInterest);

            // Calculate amount per installment
            const calculatePerInstallment = totalAmountWithInterest / getInstallment;

            // Set the calculated value if it's valid
            if (calculatePerInstallment > 0) {
                setValue('perInstallment', calculatePerInstallment.toFixed(2));
            } else {
                toast.error('Amount per installment must be greater than 0.');
                setValue('perInstallment', 0);
            }
        } else {
            // Only show error if all values are filled and still invalid
            if (getAmount && getInstallment && getLoanTypeValue) {
                toast.error('Amount and installment must be positive numbers.');
            }
            setValue('perInstallment', 0);
            setValue('interestAmount', 0);
        }
    }, [getAmount, getInstallment, getLoanTypeValue]);

    return (
        <Paper elevation={2} sx={{ p: { sm: 3, xs: 1.5 } }}>
            <Typography variant={'h5'}>New Loan Request</Typography>
            <Divider sx={{ my: 2 }}></Divider>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.client ? red[700] : ''}>
                                        Employee*
                                    </Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.client
                                                    ? '1px solid #D32F2F'
                                                    : '1px solid #C4C4C4',
                                                '&:hover': {
                                                    border: !!formState.errors?.client
                                                        ? '1px solid #D32F2F'
                                                        : '1px solid #C4C4C4',
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="client"
                                        options={employeeOPtion}
                                        value={
                                            field?.value !== ''
                                                ? employeeOPtion?.filter(
                                                      (option) => option?.value === field?.value
                                                  )[0]
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.client ? (
                                        <FormHelperText error>
                                            {errors?.client?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="client"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography
                                        color={!!formState.errors?.loanType ? red[700] : ''}
                                    >
                                        Loan Type*
                                    </Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.loanType
                                                    ? '1px solid #D32F2F'
                                                    : '1px solid #C4C4C4',
                                                '&:hover': {
                                                    border: !!formState.errors?.loanType
                                                        ? '1px solid #D32F2F'
                                                        : '1px solid #C4C4C4',
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="loanType"
                                        options={loanTypeOption}
                                        value={
                                            field?.value !== ''
                                                ? loanTypeOption?.filter(
                                                      (option) => option?.value === field?.value
                                                  )[0]
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.loanType ? (
                                        <FormHelperText error>
                                            {errors?.loanType?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="loanType"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography color={!!formState.errors?.amount ? red[700] : ''}>
                                        Amount*
                                    </Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.amount}
                                        placeholder="Enter Loan Amount"
                                        type="number"
                                        disabled={!getLoanTypeValue}
                                    />
                                    {!!formState.errors?.amount ? (
                                        <FormHelperText error>
                                            {errors?.amount?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="amount"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography
                                        color={!!formState.errors?.installment ? red[700] : ''}
                                    >
                                        Num of Installment*
                                    </Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.installment}
                                        placeholder="Enter number of installment"
                                        type="number"
                                        disabled={!getLoanTypeValue || !getAmount}
                                    />
                                    {!!formState.errors?.installment ? (
                                        <FormHelperText error>
                                            {errors?.installment?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="installment"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <Tooltip
                                    title={`(Amount + (Amount × (loan type + pf investment) Interest Rate / 100)) ÷ Number of Installments`}
                                >
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <Typography
                                            color={
                                                !!formState.errors?.perInstallment ? red[700] : ''
                                            }
                                        >
                                            Amount Per Installment*
                                        </Typography>
                                        <OutlinedInput
                                            disabled
                                            {...field}
                                            error={!!formState.errors?.perInstallment}
                                            placeholder="Enter Amount Per Installment"
                                            type="number"
                                        />
                                        {!!formState.errors?.perInstallment ? (
                                            <FormHelperText error>
                                                {errors?.perInstallment?.message}
                                            </FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>
                                </Tooltip>
                            )}
                            name="perInstallment"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography
                                        color={!!formState.errors?.interestAmount ? red[700] : ''}
                                    >
                                        Total Amount With Interest
                                    </Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.interestAmount}
                                        placeholder="Enter total amount with interest"
                                        type="number"
                                        disabled
                                    />
                                    {!!formState.errors?.interestAmount ? (
                                        <FormHelperText error>
                                            {errors?.interestAmount?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="interestAmount"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography color={!!formState.errors?.reason ? red[700] : ''}>
                                        Reason
                                    </Typography>
                                    <TextField
                                        multiline
                                        minRows={4.8}
                                        maxRowsows={10}
                                        {...field}
                                        error={!!formState.errors?.reason}
                                        placeholder="Describe Your Reason"
                                    />
                                    {!!formState.errors?.reason ? (
                                        <FormHelperText error>
                                            {errors?.reason?.message}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            )}
                            name="reason"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography>Attachment</Typography>
                        <FileUploadReport files={files} setFiles={setFiles} />
                    </Grid>
                </Grid>
                <ApiErrorMessage handleError={errorMessage} />
                <Divider sx={{ my: 3 }}></Divider>
                <Stack alignItems={'end'}>
                    {role === ROLENAME ||
                    storePermissionPath.includes(routerPermissionPath[0]) ||
                    storeRolePermissionPath.includes(routerPermissionPath[0]) ? (
                        <Button variant={'contained'} type={'submit'}>
                            Create Request
                        </Button>
                    ) : null}
                </Stack>
            </form>
        </Paper>
    );
};

export default NewLoanRequestPage;
