//name and label
const selectOptionsIdName = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.id,
            label: `${item?.name} (${item?.id})`,
        });
    });

    return storeEmployee;
};

export default selectOptionsIdName;

//name and label
export const selectOptionsEmployee = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.id,
            label: `${item?.name} (${item?.hrm_employee_id})`,
        });
    });

    return storeEmployee;
};

//name and label
export const selectOptionsFormat = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.id,
            label: `${item?.name}  `,
        });
    });

    return storeEmployee;
};

//Title and id
export const selectOptionsTitleId = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.id,
            label: `${item?.title}  `,
        });
    });

    return storeEmployee;
};

//employee name and label
export const selectOptionsEmployeeId = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.employee_id,
            label: item?.name,
        });
    });

    return storeEmployee;
};

//employee name and label
export const selectOptionsEmailID = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;

    getEmployeeData?.map((item) => {
        if (item?.role_id === 1) {
            return storeEmployee.push({
                value: item?.user_id,
                label: `${item?.email} (${item?.name})`,
                name: item?.name,
                roleID: item?.role_id,
                isDisabled: true,
            });
        } else {
            return storeEmployee.push({
                value: item?.user_id,
                label: `${item?.email} (${item?.name})`,
                name: item?.name,
                roleID: item?.role_id,
            });
        }
    });

    return storeEmployee;
};

//name and label
export const selectOptionsRolePermission = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        if (item?.id === 1) {
            return storeEmployee.push({
                value: item?.id,
                label: item?.name,
                isDisabled: true,
            });
        } else {
            return storeEmployee.push({
                value: item?.id,
                label: item?.name,
            });
        }
    });

    return storeEmployee;
};

//Loan type format
export const selectOptionsLoanType = (data = []) => {
    const storeEmployee = [];
    const getEmployeeData = data;
    getEmployeeData?.map((item) => {
        return storeEmployee.push({
            value: item?.id,
            label: `${item?.title} (${item?.interest_rate}%) `,
            interestRate: item?.interest_rate,
        });
    });

    return storeEmployee;
};

//Vehicle type options
export const vehicleOptions = [
    { label: 'Bicycle', value: 'bicycle' },
    { label: 'Bike', value: 'bike' },
    { label: 'Car', value: 'car' },
    { label: 'Van', value: 'van' },
    { label: 'Bus', value: 'bus' },
    { label: 'Truck', value: 'truck' },
    { label: 'Taxi', value: 'taxi' },
    { label: 'Rickshaw', value: 'rickshaw' },
    { label: 'Scooter', value: 'scooter' },
    { label: 'Motorcycle', value: 'motorcycle' },
    { label: 'Pickup', value: 'pickup' },
    { label: 'Minivan', value: 'minivan' },
    { label: 'SUV', value: 'suv' },
    { label: 'Sedan', value: 'sedan' },
    { label: 'Convertible', value: 'convertible' },
    { label: 'Crossover', value: 'crossover' },
    { label: 'Coupe', value: 'coupe' },
    { label: 'Jeep', value: 'jeep' },
    { label: 'Limousine', value: 'limousine' },
    { label: 'Electric Car', value: 'electric_car' },
    { label: 'Hybrid Car', value: 'hybrid_car' },
    { label: 'Sports Car', value: 'sports_car' },
    { label: 'ATV', value: 'atv' },
    { label: 'RV', value: 'rv' },
    { label: 'Tractor', value: 'tractor' },
    { label: 'Ambulance', value: 'ambulance' },
    { label: 'Fire Truck', value: 'fire_truck' },
    { label: 'Police Car', value: 'police_car' },
    { label: 'Golf Cart', value: 'golf_cart' },
    { label: 'Skateboard', value: 'skateboard' },
    { label: 'Snowmobile', value: 'snowmobile' },
    { label: 'Moped', value: 'moped' },
    { label: 'Hoverboard', value: 'hoverboard' },
    { label: 'Wheelchair', value: 'wheelchair' },
    { label: 'Segway', value: 'segway' },
    { label: 'Boat', value: 'boat' },
    { label: 'Yacht', value: 'yacht' },
    { label: 'Ferry', value: 'ferry' },
    { label: 'Train', value: 'train' },
    { label: 'Tram', value: 'tram' },
    { label: 'Subway', value: 'subway' },
    { label: 'Airplane', value: 'airplane' },
    { label: 'Helicopter', value: 'helicopter' },
    { label: 'Glider', value: 'glider' },
    { label: 'Spaceship', value: 'spaceship' },
];
