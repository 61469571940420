import useAppConfig from './useAppConfig';

function useCompanyInfo(challanYear, periodYear) {
    //Get config value
    const { data } = useAppConfig([
        'setting_app_tax_number',
        'setting_app_act_year',
        'setting_app_act_name',
        'setting_app_hr_name',
        'setting_app_hr_position',
        'setting_app_address',
        'setting_app_company_name',
        'setting_app_email',
        'setting_app_phone',
        'setting_app_hr_signature',
    ]);

    const companyInfo = {
        name: `Certificate of deduction of tax under section ${data?.setting_app_tax_number || 'N/A'} of the Income Tax Act, ${data?.setting_app_act_year || 'N/A'} in respect of "${data?.setting_app_act_name || 'N/A'}"`,
        address1: `Name and address of the person or the authority: ${data?.setting_app_company_name || 'N/A'}`,
        address2: `Period for which the payment is made: Income Year ${periodYear || 'N/A'} (AY: ${challanYear || 'N/A'}).`,
    };
    const companyInfoForPdf = {
        name: data?.setting_app_company_name,
        email: data?.setting_app_email,
        phone: data?.setting_app_phone,
        address: data?.setting_app_address,
    };
    const hrInfo = [
        data?.setting_app_hr_name || '',
        data?.setting_app_hr_position || '',
        data?.setting_app_address || '',
        data?.setting_app_hr_signature || '',
    ];
    return { companyInfo, companyInfoForPdf, hrInfo };
}

export default useCompanyInfo;
