import React from 'react';
import { loanMainStatus } from '../../utils/utils';
import { Chip } from '@mui/material';

function LoanMainStatus({ status }) {
    if (status === loanMainStatus[1]) {
        return <Chip label="Partial" size="small" color="secondary" />;
    } else if (status === loanMainStatus[2]) {
        return <Chip label="Apporved" size="small" color='success' />;
    } else if (status === loanMainStatus[3]) {
        return <Chip label="Canceled" size="small" color="error" />;
    }
    return <Chip label="Pending" size="small" color="info" />;
}

export default LoanMainStatus;
