import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { postGratuitySettings } from '../../api/fetchers/settingsFetcher';
import useGetGratuitySettings from '../../hooks/useGetGratuitySettings';

const validationSchema = Yup.object().shape({
    benefit: Yup.string().required('Gratuity Benefit is required'),
    minAge: Yup.string().required('Min Job Age is required'),
    minAgePermanent: Yup.string().required('Min  Age is required'),
    maxGratuityAmount: Yup.string(),
    minQuantity: Yup.string().required('Min quantity base is required'),
    maxQuantity: Yup.string().required('Max quantity base is required'),
    // minAgeToReceive: Yup.string().required("Min Job Age to Receive is required"),
});

const GratuitySettings = () => {
    const [allowanceActive, setAllowanceActive] = useState(false);
    const { data: gratuitySettings } = useGetGratuitySettings();

    const { control, handleSubmit, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            minAgePermanent: 0,
            minQuantity: 0,
            maxQuantity: 0,
        },
    });
    const onSubmit = (data) =>
        mutation.mutate({
            gratuity_benefit: data.benefit,
            min_job_age_permanent: data.minAge,
            min_age_permanent_job_above_ten_year: data.minAgePermanent,
            min_job_age_reciv_org_benefit: data.minAgeToReceive,
            is_max_gratuity_amount_limit: allowanceActive ? 1 : 0,
            max_gratuity_amount: allowanceActive ? data.maxGratuityAmount : '0',
            quantity_based_low_threshold: data.minQuantity,
            quantity_based_high_threshold: data.maxQuantity,
        });

    const queryClient = useQueryClient();

    //Handle Chagne Switch
    const handleAllowanceToggle = () => {
        setAllowanceActive(!allowanceActive);
    };

    useEffect(() => {
        if (gratuitySettings) {
            console.log('gratuitySettings', gratuitySettings.reponse_data || 0);
            setValue('benefit', gratuitySettings.reponse_data.gratuity_benefit || 0);
            setValue('minAge', gratuitySettings.reponse_data.min_job_age_permanent || 0);
            setValue(
                'minAgePermanent',
                gratuitySettings.reponse_data.min_age_permanent_job_above_ten_year || 0
            );
            setValue(
                'minAgeToReceive',
                gratuitySettings.reponse_data.min_job_age_reciv_org_benefit || 0
            );
            setValue('maxGratuityAmount', gratuitySettings.reponse_data.max_gratuity_amount || 0);
            setValue(
                'minQuantity',
                gratuitySettings.reponse_data.quantity_based_low_threshold || 0
            );
            setValue(
                'maxQuantity',
                gratuitySettings.reponse_data.quantity_based_high_threshold || 0
            );
            setAllowanceActive(
                Boolean(Number(gratuitySettings?.reponse_data?.is_max_gratuity_amount_limit))
            );
        }
    }, [gratuitySettings]);

    const mutation = useMutation({
        mutationFn: (formData) => {
            return postGratuitySettings(formData);
        },
        onSuccess: (data) => {
            if (data?.status === 'success') {
                queryClient.invalidateQueries('gratuity-settings');
                toast.success('Settings Updated Successfully');
            }
        },

        onError: () => {
            queryClient.invalidateQueries('gratuity-settings');
            toast.error('Something went wrong');
        },
    });

    return (
        <Paper elevation={2} sx={{ p: 2, maxWidth: '500px', margin: '0 auto' }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h5">Gratuity Settings</Typography>
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Gratuity Benefit (% of Basic)*</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.benefit}
                                        helperText={
                                            formState.errors.benefit
                                                ? formState.errors.benefit.message
                                                : null
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="benefit"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography>Eligibity</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Job Age as Permanent (in years)*</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAge}
                                        helperText={
                                            formState.errors.minAge
                                                ? formState.errors.minAge.message
                                                : null
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAge"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Age as Permanent (in years)*</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAgePermanent}
                                        helperText={
                                            formState.errors.minAgePermanent
                                                ? formState.errors.minAgePermanent.message
                                                : null
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAgePermanent"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography> Min Quantity Base </Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minQuantity}
                                        helperText={
                                            formState.errors.minQuantity
                                                ? formState.errors.minQuantity.message
                                                : null
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minQuantity"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Max Quantity Base</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.maxQuantity}
                                        helperText={
                                            formState.errors.maxQuantity
                                                ? formState.errors.maxQuantity.message
                                                : null
                                        }
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="maxQuantity"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allowanceActive}
                                    onChange={handleAllowanceToggle}
                                    name="allowanceActive"
                                />
                            }
                            labelPlacement="start"
                            label="Do you want the Max Gratuity Allowance?"
                            sx={{ ml: 0 }}
                        />
                        <Collapse in={allowanceActive}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <Typography>Max Gratuity Amount (in lakh)</Typography>
                                        <TextField
                                            {...field}
                                            error={!!formState.errors.maxGratuityAmount}
                                            helperText={
                                                formState.errors.maxGratuityAmount
                                                    ? formState.errors.maxGratuityAmount.message
                                                    : null
                                            }
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            onKeyPress={(e) => {
                                                if (e.key === '-' || e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </FormControl>
                                )}
                                name="maxGratuityAmount"
                                control={control}
                                defaultValue=""
                            />
                        </Collapse>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Stack>
            </form>
        </Paper>
    );
};

export default GratuitySettings;
