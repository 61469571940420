import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import ApiErrorMessage from '../../components/error/ApiErrorMessage';
import { useAuth } from '../../hooks/useAuth';
import { rankDataOptions } from '../../utils/utils';

const validationSchema = Yup.object().shape({
    employee: Yup.string().required('Employee is required').nullable(),
    rank: Yup.string().required('Rank is required').nullable(),
});

const AddApprovalType = ({ handleAddSubmit, handleError }) => {
    const [AllEmployeeDetails, setAllemployeeDetails] = useState([]);

    //Context
    const { token } = useAuth();

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    // get all user
    const getAllEmployee = async () => {
        try {
            const res = await Axios.post(
                '/employee/all',
                {},

                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                setAllemployeeDetails(
                    res?.data?.response_data?.map((item) => {
                        return {
                            label: `${item?.name} - ${item?.hrm_employee_id}`,
                            value: item?.id,
                        };
                    })
                );
            } else if (res.data.success === '200') {
                toast.info("You don't have all employee permissions, contact admin");
            } else {
                setAllemployeeDetails([]);
            }
        } catch (error) {
            console.log('Employee list', error);
        }
    };

    useEffect(() => {
        getAllEmployee();
    }, []);

    return (
        <Box component={'form'} onSubmit={handleSubmit(handleAddSubmit)} sx={{ minHeight: 350 }}>
            <Controller
                render={({ field, formState }) => (
                    <FormControl fullWidth size="small">
                        <Typography color={!!formState.errors?.employee ? red[700] : ''}>
                            Select Employee*
                        </Typography>
                        <ReactSelect
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    '&:hover': { borderColor: 'gray' }, // border style on hover
                                    border: !!formState.errors?.employee
                                        ? '1px solid red'
                                        : '1px solid lightgray', // default border color
                                    boxShadow: 'none', // no box-shadow
                                }),
                            }}
                            {...field}
                            options={AllEmployeeDetails}
                            className="basic-multi-select"
                            isClearable={true}
                            isSearchable={true}
                            classNamePrefix="select-user"
                            value={
                                field?.value !== ''
                                    ? AllEmployeeDetails?.filter(
                                          (row) => row?.value === field?.value
                                      )[0]
                                    : null
                            }
                            onChange={(selectedOption) => {
                                field.onChange(selectedOption?.value);
                            }}
                        />
                        {!!formState.errors?.employee ? (
                            <FormHelperText error>{errors?.employee?.message}</FormHelperText>
                        ) : (
                            ''
                        )}
                    </FormControl>
                )}
                name="employee"
                control={control}
                defaultValue=""
            />
            <Controller
                render={({ field, formState }) => (
                    <FormControl fullWidth size={'small'} sx={{ mt: 2 }}>
                        <Typography color={!!formState.errors?.rank ? red[700] : ''}>
                            Rank*
                        </Typography>
                        <ReactSelect
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    '&:hover': { borderColor: 'gray' }, // border style on hover
                                    border: !!formState.errors?.rank
                                        ? '1px solid red'
                                        : '1px solid lightgray', // default border color
                                    boxShadow: 'none', // no box-shadow
                                }),
                            }}
                            {...field}
                            options={rankDataOptions}
                            className="basic-multi-select"
                            isClearable={true}
                            isSearchable={true}
                            classNamePrefix="select-user"
                            value={
                                field?.value !== ''
                                    ? rankDataOptions?.filter(
                                          (row) => row?.value === field?.value
                                      )[0]
                                    : null
                            }
                            onChange={(selectedOption) => {
                                field.onChange(selectedOption?.value);
                            }}
                        />
                        {!!formState.errors?.rank ? (
                            <FormHelperText error>{errors?.rank?.message}</FormHelperText>
                        ) : (
                            ''
                        )}
                    </FormControl>
                )}
                name="rank"
                control={control}
                defaultValue=""
            />

            <ApiErrorMessage handleError={handleError} />
            <Stack
                justifyContent={'end'}
                direction={'row'}
                flexWrap={'wrap'}
                gap={1.5}
                container
                spacing={2}
                sx={{ mt: 10, mb: 2 }}
            >
                <Button
                    variant="contained"
                    onClick={() => reset()}
                    color="inherit"
                    sx={{ minWidth: 130 }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: 130, ml: '0 !important' }}
                >
                    Add
                </Button>
            </Stack>
        </Box>
    );
};

export default AddApprovalType;
